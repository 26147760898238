import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function TextForm(props) {
    const [text, setText] = useState("");
    
    const handleUpClick = () => {
        let newtxt = text.toUpperCase();
        setText(newtxt);
        props.showAlert("Converted to UpperCase", "Success");
    }
    const handleLoClick = () => {
        let newtxt = text.toLowerCase();
        setText(newtxt);
        props.showAlert("Converted to LowerCase", "Success");
    }
    const handleClearClick = () => {
        let newtxt = '';
        setText(newtxt);
        props.showAlert("Text Cleared", "Success");
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(text);
        props.showAlert("Text Copied", "Success");
    }
    const handleExtraSpaces = () => {
        let txt = text.split(/[ ]+/)
        setText(txt.join(" "))
        props.showAlert("Removed Extra Spaces", "Success");
    }
    const handlechange = (event) => {
         setText(event.target.value);
    }

    return (
        <>
            <div className={`container my-3 text-${props.mode==='light'?'dark':'light'}`}>
                <h1>Enter Text Below</h1>
                <textarea className={`form-control my-3 bg-${props.mode} text-${props.mode==='light'?'dark':'light'}`} id="vla" value={text} onChange={handlechange} ></textarea>
                <button disabled={text.length === 0} className='btn btn-outline-warning m-1' onClick={handleUpClick}>Convert to UpperCase</button>
                <button disabled={text.length === 0} className='btn btn-outline-primary m-1' onClick={handleLoClick}>Convert to LowerCase</button>
                <button disabled={text.length === 0} className='btn btn-outline-primary m-1' onClick={handleCopy}>Copy Text</button>
                <button disabled={text.length === 0} className='btn btn-outline-primary m-1' onClick={handleExtraSpaces}>Remove Extra Spaces</button>
                <button disabled={text.length === 0} className='btn btn-outline-primary m-1' onClick={handleClearClick}>Clear Text</button>
            </div>
            <div className={`container my-3 text-${props.mode==='light'?'dark':'light'}`}>
                <h1>Your Summary</h1>
                <p>{text.trim().length === 0 ? "0" : text.trim().split(/\n+/).length} Words & {text.length} Characters</p>
                <p>{0.008 * (text.trim().length === 0 ? "0" : text.trim().split(/\n+/).length)} Time taken to Read</p>
                <h1>Preview</h1>
                <p>{text}</p>
            </div>
        </>
    )
}

TextForm.propTypes = { heading: PropTypes.string.isRequired };