import React from 'react'

export default function About(props) {
    return (
        <>
            <div className='container p-3 py-1 mt-2 rounded' >
                <h1 className={`text-${props.mode==='light'?'dark':'light'}`}>About App</h1>
                <div className="accordion accordion-flush my-3 rounded" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button d-block collapsed bg-${props.mode==='light'?'light':'dark'} text-${props.mode==='light'?'dark':'light'}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Analyze your Text
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className={`accordion-body bg-${props.mode==='light'?'light':'dark'} text-${props.mode==='light'?'dark':'light'}`}>Text Utils Hepls to analyze your text in quickly and in better way.</div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className={`accordion-button d-block collapsed bg-${props.mode==='light'?'light':'dark'} text-${props.mode==='light'?'dark':'light'}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" >
                                Free to Use
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" >
                            <div className={`accordion-body bg-${props.mode==='light'?'light':'dark'} text-${props.mode==='light'?'dark':'light'}`}>This app is free and useful and let's you know about words and characters.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
