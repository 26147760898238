import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './components/About.js';
import Alert from './components/Alert.js';
import Navbar from './components/Navbar.js';
import TextForm from './components/TextForm.js';
import React, { useState } from 'react';

export default function App() {
  const [mode, setMode] = useState("light");

  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({msg: message, msgtype: type});
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }
  const removebg = () => {
    document.body.classList.remove('bg-light');
    document.body.classList.remove('bg-dark');
    document.body.classList.remove('bg-primary');
    document.body.classList.remove('bg-success');
    document.body.classList.remove('bg-danger');
    document.body.classList.remove('bg-warning');
  }
  const toogleFunction = (cls) => {
    removebg();
    document.body.classList.add('bg-' + cls)  
    setMode(cls);
  }

  
  return (
    <>

    <BrowserRouter>
      <Navbar title="Text Utils" mode={mode} toggle={toogleFunction} />
      <Alert alert={alert} />
      <Routes>
        <Route>
          <Route index element={<TextForm heading="Enter the text to Analize" mode={mode} showAlert={showAlert}/>} />
          <Route exact path="/about" element={<About toggle={toogleFunction} mode={mode} />} />
        </Route>
      </Routes>
    </BrowserRouter>
    
    </>
  );
}