import { Outlet, Link } from "react-router-dom";
import PropTypes from 'prop-types'

export default function Navbar(props) {

    const changeColor = (color) => {
        props.toggle(color);
    }


    return (
        <>
            <nav className={`navbar navbar-expand-lg bg-black`}>
                <div className="container-fluid">
                    <Link className={`navbar-brand d-inline text-light`} to="/">{props.title}</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                            <Link className={`nav-link text-light`} aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                            <Link className={`nav-link text-light`} to="/about">About</Link>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <div className="bg-success rounded-circle mx-2" style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={()=>changeColor('success')}></div>
                            <div className="bg-danger rounded-circle mx-2" style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => {props.toggle('danger')}}></div>
                            <div className="bg-primary rounded-circle mx-2" style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => {props.toggle('primary')}}></div>
                            <div className="bg-dark rounded-circle mx-2" style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => {props.toggle('dark')}}></div>
                            <div className="bg-light rounded-circle mx-2" style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => {props.toggle('light')}}></div>
                        </div>
                    </div>
                </div>
            </nav>
            <Outlet />
        </>
    );
}

Navbar.propTypes = { title: PropTypes.string.isRequired };
Navbar.defaultProps = { title: "Set Title Here" }